import React from 'react';
import SharedPage from './shared';

const IndexPage = ({ data }) => {
  return <SharedPage pageType="code" data={data} />;
};

export default IndexPage;

export const query = graphql`
  query guidelinesAccessibilityCodeIndexQuery($tierOne: String = "Guidelines") {
    ...indexList
  }
`;
