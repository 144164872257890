/* eslint-disable max-len */
import React from 'react';
import {
  ItemsListIndexPage,
  Link,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
} from '../../../components';
import pageHeroData from '../../../data/pages/guidelines.yml';

const SharedPage = ({ data, pageType }) => {
  return (
    <PageWithSubNav
      pageType={pageType}
      title="Accessibility"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Accessibility" />

      <Section title="Why Accessibility Matters">
        <Paragraph>
          Our mission at Hudl is to make every moment in sports count. To
          achieve that, our products and services need to be available to
          everyone, no matter how they access technology. A coach should never
          be limited from using Hudl because they don't use a typical computer
          mouse, just like an athlete should never be limited by colorblindness,
          dyslexia, or a temporary injury that affects how they use technology.
          If we don't put the effort in to make accessible products, we end up
          excluding many athletes, coaches, fans, and others who are living with
          disabilities.
        </Paragraph>
        <Paragraph>
          Uniform is committed to providing the guidance and resources needed to
          create great, accessible products at Hudl. We understand that
          accessibility is complex and daunting, so we'll do what we can to make
          it easier and more clear. We still have plenty to learn, so please
          <Link href="https://hudl.slack.com/archives/C0KBB5FKN">
            reach out to us
          </Link>{' '}
          with any questions, issues or suggestions.
        </Paragraph>
      </Section>

      <Section title="Web Content Accessibility Guidelines (WCAG)">
        <Paragraph>
          The Web Content Accessibility Guidelines (WCAG) are the gold standard
          for testing accessibility for web products. Hudl is striving to reach{' '}
          <Link href="https://www.w3.org/TR/WCAG21/">WCAG revision 2.1</Link> AA
          compliance, which is a step beyond the most basic level of compliance
          (A), and a step behind AAA, which is meant more for government
          agencies, healthcare, etc.
        </Paragraph>
        <Paragraph>
          The WCAG documentation is extensive, and comes with plenty of
          examples—it should be referenced as needed. Most importantly,
          guidelines are broken down into four categories:{' '}
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/intro#understanding-the-four-principles-of-accessibility">
            Perceivable, Operable, Understandable, and Robust (POUR)
          </Link>
          .
        </Paragraph>
        <Paragraph>
          In some Uniform documentation, we might reference a specific WCAG
          criteron, such as{' '}
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/use-of-color.html">
            WCAG 1.4.1 (Use of Color)
          </Link>
          . These are the specific rules outlined in the WCAG, and each one is
          designated A, AA, or AAA compliance level. Follow the relevant links
          to learn more about each rule and how to verify compliance.
        </Paragraph>
      </Section>
      <Section title="Guidelines and Resources">
        <Paragraph>
          Uniform documentation includes the most important aspects of
          accessible product design and development, along with what's easiest
          to implement. We'll continue to rely on third-party experts to audit
          and validate accessibility across our product suite, but we'll share
          this information internally.
        </Paragraph>
        <Paragraph>
          To get started on accessible product design, check out the resources
          available below, and be sure to use our checklists whenever designing
          a new interface!
        </Paragraph>
      </Section>

      <Section>
        <ItemsListIndexPage
          data={data}
          listType="visual"
          tierTwo="accessibility"
          listLayout="3"
        />
      </Section>
    </PageWithSubNav>
  );
};

export default SharedPage;
